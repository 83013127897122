.container {
  width: 100%;
  height: 550px;
  border-radius: 10px;
  border: 1px solid var(--sectionBorder);
  overflow-y: auto;
}

.schedule {
  padding: 16px 24px 16px 24px;
}

.schedule > div > ul > li {
  margin-bottom: 0rem;
}
