.addResources {
  gap: 1.5rem;
  display: flex;
  margin-top: 2rem;
  padding: 40px 32px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background: var(--white);
}

.addResources > h3 {
  font-size: 25px;
  font-weight: 500;
  line-height: 36px;
  font-style: normal;
  color: var(--grey);
  font-family: Archivo;
}

.addResources > p {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: var(--grey);
  font-style: normal;
  font-family: Archivo;
}

.files {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: var(--grey);
  font-style: normal;
  font-family: Archivo;
  margin-bottom: 0.5rem;
  display: block;
}

@media screen and (min-width: 779px) and (max-width: 1023px) {
  .addResources {
    gap: 1rem;
    padding: 20px 16px 0;
  }

  .addResources > h3 {
    font-size: 18px;
    line-height: 27px;
  }
}

@media screen and (max-width: 778px) {
  .addResources {
    gap: 1rem;
    padding: 20px 0 0;
  }

  .addResources > h3 {
    font-size: 18px;
    line-height: 27px;
  }
}
