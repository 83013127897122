.container {
    gap: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.container a {
    color: inherit;
    text-decoration: none;
}

.course {
    width: 100%;
    max-width: 465px;
    overflow: hidden;

    cursor: pointer;
    border-radius: 20px;

    font-style: normal;
    font-family: Archivo;
}

.course img {
    width: 100%;
    height: 206px;
    max-width: 100%;
    object-fit: cover;
}

.course h4 {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
}

.course p {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
}

.content {
    padding: 27px;
    background: var(--white);
}

.content>div {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
}

.content>div:nth-child(1)>h4:nth-child(1) {
    font-weight: 600;
}

.content>div:nth-child(2)>p:nth-child(1) {
    font-weight: 600;
}

.content>div:nth-child(2)>p:nth-child(2) {
    gap: 10px;
    display: flex;
}

.content>div:nth-child(2)>p:nth-child(2) div {
    position: relative;
}

.content>div:nth-child(2)>p:nth-child(2) span {
    display: flex;
    align-items: center;
    justify-content: center;

    top: -3px;
    right: -3px;
    position: absolute;

    width: 12px;
    height: 12px;
    padding: 5px;
    font-size: 10px;
    border-radius: 50%;
    color: var(--white);
    background: var(--black);
}

.content>div {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
}

.singleCourse {
    margin: 4rem 4.5rem;
    transform: scale(1.3);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .singleCourse {
        margin: 3rem;
        transform: scale(1.2);
    }

    .course {
        max-width: 380px;
        border: 1px solid var(--sectionBorder);

    }
}

@media screen and (max-width: 768px) {
    .course {
        width: 100%;
        max-width: 100%;
        border-radius: 10px;
        border: 1px solid var(--sectionBorder);

    }

    .course h4 {
        font-size: 16px;
        line-height: 26px;
        font-weight: 400 !important;
    }

    .course p {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400 !important;
    }

    .course p:last-child {
        font-size: 12px;
        line-height: 18px;
    }

    .content {
        padding: 20px;
    }

    .singleCourse {
        margin: 0;
        transform: scale(1);
    }
}