.container svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
  object-fit: cover;
}

.container button {
  width: fit-content;
  padding: 10px 30px;
}

.header {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 24px 48px;
  background: var(--white);
  border-top: 1px solid var(--veryLightPurple);
}

.header > p {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  font-style: normal;
  color: var(--grey);
  font-family: Archivo;
  user-select: none;
}

.body {
  gap: 1rem;
  display: flex;
  padding: 40px 48px;
  align-items: flex-start;
}

.leftSide {
  top: 10px;
  width: 100%;
  height: 587px;
  overflow-y: auto;
  max-width: 400px;
  min-width: 352px;
  position: sticky;
  background: var(--white);
}

.leftSide .top {
  padding: 24px;
  background: var(--purple);
}

.leftSide .top > h4 {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  font-style: normal;
  color: var(--white);
  font-family: Archivo;

  margin-bottom: 1.5rem;
}

.leftSide .bottom {
  padding: 24px;
  background: var(--white);

  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.moduleTitle {
  gap: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  /* padding-bottom: 0rem; */
  justify-content: space-between;
}

.moduleTitle h4 {
  flex: 1;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: var(--grey);
  font-style: normal;
  font-family: Archivo;
}

.week {
  gap: 1rem;
  display: flex;
  font-size: 13px;
  cursor: pointer;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  color: var(--grey);
  font-family: Archivo;
  margin-bottom: 0.5rem;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.courseFile {
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.courseFile > :nth-child(1) {
  font-size: 16px;
  font-weight: 500;
  color: var(--grey);
  font-style: normal;
  font-family: Archivo;
}

.courseFile > :nth-child(2) {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  color: var(--grey3);
  font-family: Archivo;

  gap: 3px;
  display: flex;
  align-items: center;
}

.rightSide {
  padding: 24px 16px;
  border-radius: 10px;
  background: var(--white);
  width: 100%;
  gap: 2rem;
  display: flex;
  flex-direction: column;
}

.rightSide p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: var(--grey);
  font-style: normal;
  font-family: Archivo;
}

.rightSide .top {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--veryLightPurple);
}

.rightSide .top > h3 {
  font-size: 31px;
  font-weight: 500;
  line-height: 41px;
  font-style: normal;
  color: var(--grey);
  font-family: Archivo;
}

.rightSide .top > p {
  padding-block: 5px;
  color: var(--timerGreen);

  gap: 10px;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}

.rightSide .bottom > div {
  gap: 1.5rem;
  display: flex;
  padding-top: 24px;
  flex-direction: column;
}

.rightSide .bottom .info h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  font-style: normal;
  color: var(--grey);
  font-family: Archivo;
}

@media screen and (min-width: 778px) and (max-width: 1024px) {
  .leftSide {
    display: none;
    position: relative;
  }
}

@media screen and (max-width: 778px) {
  .container svg {
    width: 16px;
    height: 16px;
  }

  .container button {
    padding: 10px;
  }

  .header {
    gap: 10px;
  }

  .header {
    padding: 16px;
    border-top: none;
    border-bottom: 1px solid var(--veryLightPurple);
  }

  .header > p {
    font-size: 10px;
    line-height: 18px;
  }

  .body {
    gap: 2rem;
    padding: 2rem 1rem;
    flex-direction: column;
    background: var(--white);
  }

  .leftSide {
    display: none;
    position: relative;
  }
}
