.container {
  width: 100%;
  user-select: none;
}

.listNav {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  min-width: 320px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-bottom: 1px solid var(--sectionBorder);
}

.listNav::-webkit-scrollbar {
  display: none;
}

.div {
  text-align: center;
  padding: 1.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  width: 50%;
  color: var(--disabled);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  white-space: nowrap;
}

.div>span {
  cursor: pointer;
}

.activeDiv {
  width: 50%;
  text-align: center;
  padding: 1rem;
  color: var(--timerGreen);
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 10px 10px 0 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

@keyframes borderEffect {
  0% {
    border-bottom: 2px solid #f2f3f8;
  }

  100% {
    border-bottom: 2px solid rgb(28 37 78 / 1);
  }
}

.activeDiv>span {
  cursor: pointer;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .container {
    position: static;
  }
}

@media (max-width: 768px) {
  .container {
    position: relative;
    overflow: auto;
  }

  .div {
    font-size: 13px;
    line-height: 18px;
  }

  .activeDiv {
    font-size: 13px;
    line-height: 18px;
  }
}