.container {
  width: 876px;
  height: 573px;
  padding: 27px 100px;
}

.container > h4 {
  font-family: Archivo;
  font-size: 25px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--black);
  padding: 1rem 0;
  border-bottom: 1px solid var(--getStartedBorder);
  margin-bottom: 0.5rem;
}

.info {
  margin-top: 1rem;
}

.info > h5 {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--grey3);
  margin-bottom: 0.5rem;
}

.info > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: var(--black);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.info > p > span:nth-child(2) {
  margin-left: 0.8px;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-family: Archivo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--purple);
}

.buttonSection {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 40px;
}

.buttonSection > button:nth-child(1) {
  color: var(--red);
  width: 111px;
}

.buttonSection > button:nth-child(2) {
  width: 198px;
  margin-left: auto;
}

.buttonSection > button:nth-child(3) {
  width: 102px;
}
