.container {
  padding: 40px 2rem;
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.container>h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 0.5rem;
}

.container>ol {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-left: -20px;
}

.container>ul>li {
  margin-bottom: 0.5rem;
}

.copyCode {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: 1px solid var(--borderGrey);
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.copyCode>span:nth-child(1) {
  color: #000;
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.copyCode>span:nth-child(2) {
  color: var(--purple);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.videoGuide {
  gap: 1rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  align-items: center;
  justify-content: space-between;
}

.videoGuide span {
  text-decoration: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: fit-content;
  border: 0;
  padding: 0;
  color: var(--purple);
  background: var(--white);
}

.buttonInner {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonInner path {
  stroke: var(--purple);
}

.videoGuide button:hover .buttonInner path {
  stroke: var(--white);
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }

  .container>h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .container>ol {
    font-size: 14px;
    line-height: 23px;
  }

  .copyCode {
    padding: 0.5rem;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  .copyCode>span:nth-child(1) {
    font-size: 16px;
    line-height: 25px;
  }

  .copyCode>span:nth-child(2) {
    font-size: 10px;
    line-height: 18px;
  }

  .videoGuide button {
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
  }

  .container>h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .container>ol {
    font-size: 14px;
    line-height: 23px;
  }

  .copyCode {
    padding: 0.5rem;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  .copyCode>span:nth-child(1) {
    font-size: 16px;
    line-height: 25px;
  }

  .copyCode>span:nth-child(2) {
    font-size: 10px;
    line-height: 18px;
  }

  .videoGuide button {
    font-size: 14px;
    line-height: 23px;
  }
}