.container {
  padding: 1.5rem 2rem;
  height: 100%;
  overflow: auto;
  background-color: var(--sideNavBackground);
}

.logoSection {
  width: 53px;
  height: 50px;
}

.navSection {
  margin-top: 30px;
}

.navSection>a,
.navSection>div:not(.otherOptions) {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1.5rem;
  text-decoration: none;
  border-radius: 4px;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  flex-wrap: wrap;
  padding: 10px;
}

.navSection>div:not(.otherOptions) {
  padding: 0;
}

.otherOptions>a {
  text-decoration: none;
  padding: 10px 10px 10px 42px;
  border-radius: 4px;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: block;
  color: var(--grey);
  white-space: nowrap;
}

.otherOptions {
  flex-basis: 100%;
  overflow-y: hidden;
  transition: all .3s ease-in-out
}

.otherOptionsLinkActive {
  color: var(--purple) !important;
}

.active {
  padding: 10px;
  color: var(--grey);
  stroke: var(--grey);
  background-color: var(--veryLightPurple);
}

.inActive {
  padding: 10px;
  stroke: var(--grey);
  color: var(--grey);
}

.active:nth-child(4),
.inActive:nth-child(4) {
  stroke: none;
}

.link {
  text-decoration: none;
  border-radius: 4px;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}

.link:nth-child(2) {
  flex: 1;
}

.moreMenuContentInActive {
  color: var(--grey);
  border-radius: 5px;
  width: 100%;
  gap: 10px;
  display: flex;
  padding: 10px;
}

.moreMenuContentActive {
  background-color: var(--veryLightPurple);
  color: var(--grey);
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  gap: 10px;
  display: flex;
}

.moreMenuContentInActive a {
  color: var(--grey);
  display: flex;
}

.moreMenuContentActive a {
  color: var(--grey);
  display: flex;
}

.logout {
  margin-top: 3rem;
  padding: 10px !important;
}