.container {
    width: 100%;
    display: flex;
    padding: 32px 0;
    flex-direction: column;
    justify-content: center;
}

.container h1 {
    font-size: 31px;
    font-weight: 500;
    line-height: 41px;
    color: var(--grey);
    font-style: normal;
    font-family: Archivo;
}

.container .addLesson {
    gap: 1.5rem;
    display: flex;
    margin-top: 2rem;
    padding: 40px 32px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    background: var(--white);
}

.container .body {
    padding: 0;
    background: transparent;
}

.subContainer>h2 {
    font-size: 25px;
    font-weight: 500;
    line-height: 36px;
    font-style: normal;
    color: var(--grey);
    font-family: Archivo;
}

.subContainer>h2 {
    padding: 40px 32px 0;
    background: var(--white);
    border-radius: 10px 10px 0 0;
}

.addLesson button {
    padding: 10px 30px;
}

.addLesson button:nth-child(3) {
    flex: 1;
    color: var(--red);
    background: var(--white);
    justify-content: flex-start;
}

.addLesson .inactivePrimary {
    pointer-events: none;
    color: var(--white);
    background: var(--disabled);
}

.addLesson .inactiveSecondary {
    pointer-events: none;
    color: var(--disabled);
    border-color: var(--disabled);
}

.buttonContainer {
    flex-wrap: wrap;
    flex-direction: row-reverse !important;
    justify-content: space-between !important;
}

.bulkUpload {
    gap: 1.5rem;
    width: 100%;
    display: flex;
    padding: 0 32px 40px;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
    background: var(--white);
    border-radius: 0 0 10px 10px;
}

.bulkUpload ul {
    margin-top: 20px;
    list-style-type: disc;
    padding-inline-start: 12px;
}

.bulkUpload .info,
.bulkUpload ul li {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--grey);
    font-style: normal;
    font-family: Archivo;
    margin-bottom: 5px;
}

.bulkUpload .info {
    padding: 8px;
    margin-top: -1rem;
    background: #FFEDAD;
}

.fileUpload {
    padding: 10px;
    margin-top: 2rem;
    border-radius: 10px;
    border: 1px solid var(--veryLightPurple);
}

.fileUpload>h3 {
    font-size: 25px;
    font-weight: 400;
    line-height: 36px;
    color: var(--grey);
    font-style: normal;
    font-family: Archivo;

    padding-block: 12px;
    border-bottom: 1px solid var(--veryLightPurple);
}

.fileUpload .file {
    gap: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px 10px 0;
    justify-content: space-between;
}

.fileUpload .file>button {
    color: var(--black);
    height: fit-content;
    border: 1px solid var(--black);
}

.fileUpload .file>div {
    flex: 1;
    width: 100%;
    max-width: 280px;
    min-width: 230px;
}

.fileUpload .file>div>p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: var(--grey);
    font-style: normal;
    font-family: Archivo;
    margin-bottom: 1rem;
}

.fileUpload .file>div>span {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--grey);
    font-style: normal;
    font-family: Archivo;
}

@media screen and (min-width: 779px) and (max-width: 1023px) {
    .container {
        gap: 1.2rem;
        padding: 16px;
    }

    .container .addLesson {
        gap: 1rem;
        padding: 16px;
    }

    .sectionsNavSection,
    .bulkUpload {
        padding: 10px;
    }

    .buttonContainer {
        justify-content: center !important;
        border: 1px solid var(--veryLightPurple);
    }

    .addLesson button:nth-child(3) {
        flex: unset;
        justify-content: unset;
    }
}

@media screen and (max-width: 778px) {
    .container {
        padding: 0;
        gap: 1.2rem;
        background: var(--white);
    }

    .container .addLesson {
        gap: 1rem;
        padding: 0;
    }

    .container h1 {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 1.5rem;
    }

    .subContainer>h2,
    .sectionsNavSection,
    .bulkUpload {
        padding: 0;
    }

    .subContainer>h2 {
        font-size: 18px;
        line-height: 27px;
    }

    .bulkUpload .info,
    .bulkUpload ul li {
        font-size: 12px;
        line-height: 18px;
    }

    .fileUpload>h3 {
        font-size: 18px;
        line-height: 26px;
        padding-block: 7px;
    }

    .fileUpload .file {
        padding: 10px;
    }

    .fileUpload .file>div>p {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 535px) {
    .buttonContainer {
        padding: 20px 10px !important;
        justify-content: center !important;
        border: 1px solid var(--veryLightPurple);
    }

    .addLesson button:nth-child(3) {
        flex: unset;
        justify-content: unset;
    }
}