.container {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.container span {
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  line-height: 26px;
  color: var(--grey);
  font-style: normal;
  font-family: Archivo;
  white-space: nowrap;
}
