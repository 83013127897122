.container {
  display: flex;
  margin-block: 24px;
  border-radius: 10px;
  padding: 1.5rem 2rem;
  flex-direction: column;
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.header>div:nth-child(1)>h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 0.8rem;
}

.header>div:nth-child(1)>p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.header>div:nth-child(2) {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--purple);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  cursor: pointer;
}

.subContainer {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.badge {
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid var(--veryLightPurple);
  background: var(--sideNavBackground);
  width: 225px;
  height: 146px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.badge>span:nth-child(1) {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--purple);
  border-radius: 50%;
}

.badge>p:nth-child(2) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin: 0.5rem 0;
  text-align: center;
}

.badge>p:nth-child(3) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

@media screen and (min-width: 767px) and (max-width: 1380px) {

  .container {
    padding: 1.5rem 1rem;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    margin-top: 1.5rem;
  }

  .header>div:nth-child(1)>h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .header>div:nth-child(1)>p {
    font-size: 10px;
    line-height: 18px;
  }

  .header>div:nth-child(2) {
    font-size: 10px;
    line-height: 18px;
  }

  .badge {
    padding: 1rem;
    width: 145px;
    height: 117px;
  }

  .badge>span:nth-child(1) {
    display: flex;
    width: 30px;
    height: 30px;
  }

  .badge>p:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }

  .badge>p:nth-child(3) {
    font-size: 8px;
    line-height: 15px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1.5rem 1rem;
  }

  .header>div:nth-child(1)>h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .header>div:nth-child(1)>p {
    font-size: 10px;
    line-height: 18px;
  }

  .header>div:nth-child(2) {
    font-size: 10px;
    line-height: 18px;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    margin-top: 1.5rem;
  }

  .badge {
    padding: 1rem;
    width: 145px;
    height: 117px;
  }

  .badge>span:nth-child(1) {
    display: flex;
    width: 30px;
    height: 30px;
  }

  .badge>p:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }

  .badge>p:nth-child(3) {
    font-size: 8px;
    line-height: 15px;
  }
}