.header {
  margin: 24px 0;
}

.header > h2 {
  font-family: Archivo;
  font-size: 25px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 0.5rem;
}

.header > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
}

.body > h4 {
  font-family: Archivo;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 0.5rem;
}

.body > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 1rem;
}

.body > ul {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: var(--grey3);
  list-style-type: disc;
}

.buttonSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;
  margin-top: 40px;
}

.buttonSection > button {
  max-width: 126px;
}
