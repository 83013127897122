.header button {
  width: fit-content;
  padding: 10px 30px;
}

.header,
.header > div {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  padding: 24px 48px;
  background: var(--white);
  border-top: 1px solid var(--veryLightPurple);
}

.header > div span {
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  font-style: normal;
  color: var(--grey);
  font-family: Archivo;
  cursor: pointer;
}

.header > div button {
  border-color: var(--black);
}
