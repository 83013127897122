.container {
  gap: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 3rem 1rem;
  align-items: stretch;
}

.leftSection {
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 80%;
  flex-direction: column;
}

.contentSection {
  gap: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.contentSection div:nth-child(1) {
  width: 30%;
  height: 30%;
  background-color: var(--white);
}

.contentSection div:nth-child(2) {
  width: 100%;
  height: 100%;
  background-color: var(--white);
}

.contentSection div:nth-child(3) {
  width: 100%;
  height: 100%;
  background-color: var(--white);
}

.divider {
  width: 100%;
  height: 2rem;
  margin: 3rem 0;
  background-color: var(--white);
}

.rightSection {
  flex: 1;
  width: 100%;
  max-width: 20%;
}

.rightSection>div {
  width: 100%;
  height: 100%;
  background-color: var(--white);
}