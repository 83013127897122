.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container h1 {
    font-size: 31px;
    font-weight: 500;
    line-height: 41px;
    color: var(--grey);
    font-style: normal;
    font-family: Archivo;
}

.container .body {
    padding: 0;
    background: transparent;
}

.textEditor {
    padding: 1rem;
    border-radius: 7px;
    background: var(--sectionsNav);
}

.file,
.textEditorHeader {
    gap: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.textEditorHeader>h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: var(--grey);
    font-style: normal;
    font-family: Archivo;
}

.addAssignmentDetails,
.container .addLesson {
    gap: 1.5rem;
    display: flex;
    margin-top: 2rem;
    padding: 40px 32px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    background: var(--white);
}

.addAssignmentDetails {
    padding: 20px 32px;
    border: 1px solid var(--veryLightPurple);
}

.addAssignmentDetails>h1 {
    margin-bottom: 1rem;
}

.namedAssignmentDetails {
    padding: 0;
    margin-top: 0;
}


.namedAssignmentDetails>h1 {
    margin-bottom: 3rem;
}

.namedAssignmentDetails>.assignmentDetails {
    padding: 40px 32px;
    border-radius: 10px;
    background: var(--white);
}

.addLesson button {
    padding: 10px 30px;
}

.addLesson>button:nth-child(3) {
    flex: 1;
    color: var(--red);
    background: var(--white);
    justify-content: flex-start;
}

.addLesson .inactivePrimary {
    pointer-events: none;
    color: var(--white);
    background: var(--disabled);
}

.addLesson .inactiveSecondary {
    pointer-events: none;
    color: var(--disabled);
    border-color: var(--disabled);
}

.buttonContainer {
    flex-wrap: wrap;
    flex-direction: row-reverse !important;
    justify-content: space-between !important;
}

.info {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--grey);
    font-style: normal;
    font-family: Archivo;
    margin-bottom: 5px;

    padding: 8px;
    margin-top: 1rem;
    background: #FFEDAD;
}

.fileUpload {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid var(--veryLightPurple);
}

.fileUpload>h3 {
    font-size: 25px;
    font-weight: 400;
    line-height: 36px;
    color: var(--grey);
    font-style: normal;
    font-family: Archivo;

    padding-block: 12px;
    border-bottom: 1px solid var(--veryLightPurple);
}

.file {
    margin-bottom: 0;
    padding: 24px 16px;
}

.addModulesBottom {
    gap: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.addModulesBottom>div {
    flex: 1;
}

@media screen and (min-width: 779px) and (max-width: 1023px) {
    .container {
        gap: 1.2rem;
        padding: 16px;
    }

    .container .addLesson {
        gap: 1rem;
        padding: 16px;
    }

    .sectionsNavSection {
        padding: 10px;
    }

    .namedAssignmentDetails>.assignmentDetails,
    .addAssignmentDetails {
        padding: 16px;
    }

    .buttonContainer {
        justify-content: center !important;
        border: 1px solid var(--veryLightPurple);
    }

    .addLesson button:nth-child(3) {
        flex: unset;
        justify-content: unset;
    }
}

@media screen and (max-width: 778px) {
    .container {
        padding: 0;
        gap: 1.2rem;
        background: var(--white);
    }

    .container .addLesson {
        gap: 1rem;
        padding: 0;
    }

    .textEditor {
        padding: 0;
        border-radius: 0;
        margin-block: 40px;
        background: var(--white);
    }

    .container h1 {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 40px;
    }

    .namedAssignmentDetails>.assignmentDetails,
    .addAssignmentDetails {
        padding: 0;
        border: none;
    }

    .info {
        font-size: 12px;
        line-height: 18px;
    }

    .fileUpload>h3 {
        font-size: 18px;
        line-height: 26px;
        padding-block: 7px;
    }
}

@media screen and (max-width: 535px) {
    .buttonContainer {
        padding: 20px 10px !important;
        justify-content: center !important;
        border: 1px solid var(--veryLightPurple);
    }

    .addLesson button:nth-child(3) {
        flex: unset;
        justify-content: unset;
    }
}