.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background: rgba(0, 0, 0, 0.4);
  cursor: auto;
}

.modal {
  position: fixed;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
  overflow: auto;
  /* overflow: scroll; */
  background-color: white;
  margin: auto;
  animation: appear 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-sizing: border-box;
  width: auto;
  height: auto;
  min-height: 10vh;
  min-width: 10vw;
  max-height: 90vh;
  max-width: 90vw;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal > div {
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: left;
  box-sizing: border-box;
}

.modal > div::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
}

.modal > div::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.modal > div::-webkit-scrollbar-thumb {
  background: #dcd7fe;
  border-radius: 10px;
}

.footer {
  text-align: right;
  margin-right: 3rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .modal {
    width: 60%;
    height: 35vh;
  }
}

@media (max-width: 767px) {
  .modal {
    height: 100%;
    width: 100%;
    max-width: 378px;
    min-width: 378px;
    min-height: 375px;
    max-height: 375px;
  }
}

@media (max-width: 500px) {
  .modal {
    height: 100%;
    width: 100%;
    max-width: 90%;
    min-width: 90%;
    min-height: 375px;
    max-height: 375px;
  }
}
