.container {
  padding: 40px 2rem;
}

.container>div {
  margin-top: 40px;
}

.getStartedVideo {
  display: none;
}

.rightCTASection {
  height: 100%;
  border-radius: 10px;
  background: var(--white);
  overflow-x: auto;
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.rightCTASection::-webkit-scrollbar {
  display: none;
}

.rightCTASection>section {
  gap: 2rem;
  display: flex;
  align-items: center;
  height: 285px;
  width: 100%;
}

.rightCTASection>section>div {
  height: 100%;
  min-width: 270px;
  margin-block: 1.5rem;
}

.carousel {
  border-radius: 10px;
  padding: 24px 32px;
  background: var(--white);
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }

  .rightCTASection {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
  }

  .container>div {
    margin-top: 20px;
  }

  .getStartedVideo {
    display: block;
  }

  .rightCTASection {
    display: block;
  }

  .rightCTASection>section>div {
    min-width: 225px;
  }

  .container>img {
    width: 184px;
    height: 184px;
  }
}