.subHeader {
  display: flex;
  padding: 1rem;
  margin: 2rem 0;
  align-items: center;
}

.subHeader > div {
  gap: 1.5rem;
  display: flex;
  align-items: center;
}

.subHeader > div > div:nth-child(1) {
  width: 64px;
  height: 64px;
  padding: 1rem;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: var(--bodyBackgroundColor);
}

.subHeader > div > div svg {
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.subHeader > button {
  margin-left: auto;
  width: 250px;
}

.subHeader > div h3 {
  font-size: 31px;
  font-weight: 500;
  line-height: 41px;
  font-style: normal;
  color: var(--grey);
  font-family: Archivo;
}

.subHeader > div p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--grey);
  margin-top: 0.5rem;
}

.subContainer {
  gap: 0.2rem;
  display: flex;
  padding: 40px 32px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background: var(--white);
}

.subContainer > h4 {
  font-size: 25px;
  font-weight: 500;
  line-height: 36px;
  color: var(--grey);
  font-style: normal;
  font-family: Archivo;
}

.subContainer > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  font-style: normal;
  color: var(--grey3);
  font-family: Archivo;
}

.subContainer button {
  width: 195px;
  margin: 1rem 0;
}

@media screen and (max-width: 1280px) {
  .subContainer {
    max-width: 100%;
    padding: 0;
  }
}

@media screen and (max-width: 1024px) {
  .subHeader {
    padding: 0;
    margin: 2rem 0 1rem;
  }

  .subHeader > div h3 {
    font-size: 18px;
    line-height: 27px;
  }

  .subHeader > div > div {
    width: 44px;
    height: 44px;
    padding: 10px;
  }

  .subContainer h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .subContainer > p {
    font-size: 14px;
    line-height: 23px;
  }
}
