.container {
  padding: 1.5rem;
}

.header {
  padding: 40px 2rem;
  color: var(--white);
  font-family: Archivo;
  font-size: 39px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background: url("../../Assets/Images/supportHeaderBg.svg"), var(--purple);
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }

  .header {
    font-size: 2rem;
    line-height: 30px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
      0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
  }

  .header {
    padding: 2rem;
    font-size: 25px;
    line-height: 30px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
      0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 32px;
  }
}