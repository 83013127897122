.container {
  padding: 1rem 90px;
  width: 876px;
  height: 767px;
}

.header > h2 {
  font-family: Archivo;
  font-size: 25px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 0.5rem;
}

.header > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey3);
}

.body {
  margin: 1.5rem 0;
}

.attendance > h4 {
  font-family: Archivo;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: var(--grey);
}

.attendance > p {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--grey3);
}

.attendance > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
  margin-top: 1rem;
}

.buttonSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;
}

.buttonSection > button {
  max-width: 190px;
}
