.bodyContainer {
  width: 100%;
  max-width: 900px;
  min-height: 100%;
  display: flex;
  align-items: stretch;
  gap: 24px;
  flex-wrap: wrap;
  padding: 24px;
  margin: auto;
  justify-content: center;
}

.bodyContainer>div:nth-child(1),
.bodyContainer>div:nth-child(2) {
  flex: 33%;
  width: 100%;
  max-width: 400px;
}

.bodyContainer>div:nth-child(3) {
  flex: 100%;
  width: 100%;
  max-width: 816px;
}

@media screen and (max-width: 767px) {
  .bodyContainer {
    gap: 16px;
    padding: 16px;
  }

  .bodyContainer>div:nth-child(1),
  .bodyContainer>div:nth-child(2) {
    flex: 100%;
    max-width: 100%;
  }
}