.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container h1 {
  font-size: 31px;
  font-weight: 500;
  line-height: 41px;
  color: var(--grey);
  font-style: normal;
  font-family: Archivo;
}

.container .body {
  padding: 0;
  background: transparent;
}

.textEditor {
  padding: 1rem;
  border-radius: 7px;
  margin: 1rem 0 2rem;
  background: var(--sectionsNav);
}

.dropdown {
  max-width: 426px;
}

.questionSectionHeader,
.questionSectionHeader > div {
  gap: 1.2rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: space-between;
}

.questionSectionHeader > div > h3 {
  font-size: 25px;
  font-weight: 500;
  line-height: 26px;
  color: var(--grey);
  font-style: normal;
  font-family: Archivo;
}

.questionSection > .textEditor {
  border-radius: 0;
  padding: 0 0 30px;
  background: var(--white);
  border-bottom: 1px solid var(--veryLightPurple);
}

.explainAnswer {
  padding-top: 24px;
}

.explainAnswer > h4,
.answerSection > span,
.textEditor h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: var(--grey);
  font-style: normal;
  font-family: Archivo;
  margin-bottom: 1rem;
}

.textEditorHeader {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.addAssignmentDetails,
.container .addLesson {
  gap: 1.5rem;
  display: flex;
  margin-top: 2rem;
  padding: 40px 32px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background: var(--white);
}

.addLesson {
  overflow: hidden;
}

.addAssignmentDetails {
  padding: 20px 32px;
  border: 1px solid var(--veryLightPurple);
}

.addAssignmentDetails > h1 {
  margin-bottom: 1rem;
}

.namedQuizDetails {
  padding: 0;
  margin-top: 0;
}

.namedQuizDetails > h1 {
  margin-bottom: 3rem;
}

.namedQuizDetails > .assignmentDetails {
  padding: 40px 32px;
  border-radius: 10px;
  background: var(--white);
}

.addLesson button {
  padding: 10px 30px;
}

.addLesson > button:nth-child(3) {
  flex: 1;
  color: var(--red);
  background: var(--white);
  justify-content: flex-start;
}

.addLesson .inactivePrimary {
  pointer-events: none;
  color: var(--white);
  background: var(--disabled);
  border: 2px solid red;
}

.addLesson .inactiveSecondary {
  pointer-events: none;
  color: var(--disabled);
  border-color: var(--disabled);
}

.buttonContainer {
  flex-wrap: wrap;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
}

.addModulesBottom {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addModulesBottom > div {
  flex: 1;
}

.addNewQuestion {
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  cursor: pointer;
  padding: 40px 32px;
  margin-block: 2rem;
  border-radius: 10px;
  border: 2px dashed var(--grey);

  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: var(--grey);
  font-style: normal;
  font-family: Archivo;
}

.checkBoxForAnswer {
  gap: 10px;
  display: flex;
  align-items: center;

  margin-top: 1rem;

  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: var(--grey);
  font-style: normal;
  font-family: Archivo;
}

@media screen and (min-width: 779px) and (max-width: 1023px) {
  .container {
    gap: 1.2rem;
    padding: 16px;
  }

  .container .addLesson {
    gap: 1rem;
    padding: 16px;
  }

  .sectionsNavSection {
    padding: 10px;
  }

  .namedQuizDetails > .assignmentDetails,
  .addAssignmentDetails {
    padding: 16px;
  }

  .buttonContainer {
    justify-content: center !important;
    border: 1px solid var(--veryLightPurple);
  }

  .addLesson button:nth-child(3) {
    flex: unset;
    justify-content: unset;
  }

  .questionSection > .textEditor {
    padding: 0;
  }

  .addNewQuestion {
    justify-content: center;

    padding: 24px 16px;
    margin-block: 1rem;

    font-size: 16px;
    line-height: 23px;
  }
}

@media screen and (max-width: 778px) {
  .container {
    padding: 0;
    gap: 1.2rem;
    background: var(--white);
  }

  .container .addLesson {
    gap: 1rem;
    padding: 0;
  }

  .textEditor {
    padding: 0;
    border-radius: 0;
    margin-block: 40px;
    background: var(--white);
  }

  .container h1 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 40px;
  }

  .questionSectionHeader > div > h3 {
    font-size: 18px;
    line-height: 25px;
  }

  .namedQuizDetails > .assignmentDetails,
  .addAssignmentDetails {
    padding: 0;
    border: none;
  }

  .questionSection > .textEditor {
    padding: 0;
  }

  .addNewQuestion {
    justify-content: center;

    padding: 24px 16px;
    margin-block: 1rem;

    font-size: 16px;
    line-height: 23px;
  }

  .questionSectionContainer {
    border-radius: 10px;
    padding: 20px 10px !important;
    border: 1px solid var(--grey);
  }
}

@media screen and (max-width: 535px) {
  .buttonContainer {
    padding: 20px 10px !important;
    justify-content: center !important;
    border: 1px solid var(--veryLightPurple);
  }

  .addLesson button:nth-child(3) {
    flex: unset;
    justify-content: unset;
  }
}
