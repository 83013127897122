.container {
  margin-top: 24px;
}

.header > h4 {
  font-family: Archivo;
  font-size: 25px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 0.5rem;
}

.header > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey3);
}

.availabilituyFormatter {
  margin: 24px 0;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.availabilituyFormatter > p {
  font-family: Archivo;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  cursor: pointer;
}

.availabilituyFormatter > .activeFormatter {
  color: var(--purple);
  border-bottom: 2px solid var(--purple);
}

.availabilituyFormatter > .inActiveformatter {
  color: var(--grey3);
  border-bottom: 2px solid transparent;
}

.availabilityPicker {
  width: 75%;
  padding: 24px;
  background-color: var(--bodyBackgroundColor);
  margin-bottom: 24px;
}

.container > p {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--grey3);
}

.buttonSection {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 40px;
}

.buttonSection > button {
  width: auto;
  max-width: 181px;
  min-width: 97px;
}

.buttonSection > button:nth-child(2) {
  margin-left: auto;
}
