.container {
  padding: 2rem;
}

.header {
  padding: 24px 32px;
  border-radius: 10px;
  margin-bottom: 2rem;
  background: var(--white);
}

.header h2 {
  font-size: 49px;
  font-weight: 500;
  line-height: 61px;
  color: var(--grey);
  font-style: normal;
  font-family: Archivo;
}

.subContainer {
  gap: 2rem;
  display: flex;
}

.subContainer > div:nth-child(1) {
  flex: 60%;
}

.subContainer > div:nth-child(2) {
  flex: 40%;
  overflow: hidden;
}

@media screen and (max-width: 1180px) {
  .container {
    padding: 1rem;
    background: var(--white);
  }

  .header {
    padding: 0;
    margin-bottom: 0;
  }

  .header h2 {
    font-size: 31px;
    line-height: 40px;
    margin-bottom: 2rem;
  }

  .subContainer {
    flex-wrap: wrap;
  }
}
