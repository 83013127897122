.container {
    gap: 1.5rem;
    width: 100%;
    display: flex;
    padding: 40px 32px;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
    background: var(--white);
    border-radius: 0 0 10px 10px;

    font-weight: 400;
    color: var(--grey);
    font-style: normal;
    font-family: Archivo;
}

.container h3 {
    font-size: 25px;
    font-weight: 500;
    line-height: 36px;
}

.container>div:nth-child(1) p {
    font-size: 16px;
    line-height: 25px;
    padding-top: 10px;
}

.container>div:nth-child(1) svg {
    display: none;
}

.container .buttonContainer {
    gap: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}


.container .buttonContainer>button {
    width: fit-content;
    padding: 10px 30px;
}

@media screen and (max-width: 768px) {
    .container {
        gap: 1rem;
        padding: 24px 16px;
    }

    .container h3 {
        font-size: 18px;
        line-height: 25px;
    }

    .container>div:nth-child(1) {
        display: flex;
        justify-content: space-between;
    }

    .container>div:nth-child(1) p {
        font-size: 12px;
        padding-top: 5px;
        line-height: 20px;
    }

    .container>div:nth-child(1) svg {
        display: block;
        cursor: pointer;
    }

    .container .buttonContainer>button:nth-child(1) {
        display: none;
    }
}