.container {
    padding: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container h2 {
    font-size: 39px;
    font-weight: 500;
    line-height: 50px;
    font-style: normal;
    color: var(--grey);
    font-family: Archivo;
}

.container .body {
    gap: 1rem;
    display: flex;
    margin-top: 6rem;
    padding: 40px 32px;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    background: var(--white);
}

.container .body>h4,
.container .body>p {
    font-size: 25px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    font-style: normal;
    color: var(--grey);
    font-family: Archivo;
}

.container .body>p {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
}

.container .body>button {
    width: fit-content;
    padding: 10px 30px;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 4rem 2rem;
    }

    .container h2 {
        font-size: 1.7rem;
        line-height: 40px;
    }

    .container .body {
        gap: 10px;
        margin-top: 5rem;
        padding: 35px 27px;
    }

    .container .body>h4,
    .container .body>p {
        font-size: 22px;
        line-height: 30px;
    }

    .container .body>p {
        font-size: 14px;
        line-height: 21px;
    }

    .container .body>button {
        margin-top: 1rem;
    }
}