.container {
  width: 876px;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  padding: 65px 255px;
}

.container > img {
  margin-bottom: 1rem;
}

.container > h2 {
  font-family: Archivo;
  font-size: 25px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  color: var(--grey);
  margin-bottom: 0.5rem;
}

.container > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: var(--grey3);
  margin-bottom: 24px;
}

.buttonSection {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.buttonSection > button {
  min-width: 198px;
}
