.container {
    width: 100%;
    height: 530px;
    min-width: 470px;
    border-radius: 10px;
    border: 1px solid var(--sectionBorder);
}

.listNav {
    gap: 1rem;
    width: 100%;
    display: flex;
    padding: 1.5rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--sectionBorder);
}

.listNav h4 {
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
}

.content {
    padding: 1.5rem;
}

.content img {
    width: 100%;
    height: 300px;
    max-width: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1540px) {
    .container {
        width: 100%;
        min-width: 315px;
    }
}

@media screen and (max-width: 1540px) {
    .container {
        width: 100%;
        min-width: 315px;
    }
}