.container {
  margin-top: 24px;
}

.header {
  margin-bottom: 24px;
}

.header > h4 {
  font-family: Archivo;
  font-size: 25px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 0.5rem;
}

.header > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey3);
}

.buttonSection {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 40px;
}

.buttonSection > button {
  width: auto;
  max-width: 181px;
  min-width: 97px;
}

.buttonSection > button:nth-child(2) {
  margin-left: auto;
}
