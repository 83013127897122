.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    width: 100%;
    display: flex;
    max-width: 832px;
    padding: 1rem 1rem 0;
    align-items: flex-start;
    justify-content: space-between;
}

.header h4 {
    color: var(--grey);
    font-family: Archivo;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 0.5rem;
}

.header p {
    color: var(--grey);
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    width: 100%;
    padding-right: 2rem;
}

.container .header svg {
    width: 45px;
    height: 45px;
    cursor: pointer;
    padding-top: 1rem;
}

@media screen and (max-width: 1023px) {
    .container h4 {
        font-size: 18px;
        line-height: 27px;
    }

    .container p {
        font-size: 14px;
        line-height: 23px;
    }
}