.container {
    display: flex;
    flex-direction: column;
}

.subHeader {
    display: flex;
    padding: 1rem;
    margin: 2rem 0;
    align-items: center;
}

.subHeader>div {
    gap: 1.5rem;
    display: flex;
    align-items: center;
}

.subHeader>div>div {
    width: 64px;
    height: 64px;
    padding: 1rem;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: var(--bodyBackgroundColor);
}

.subHeader>div>div svg {
    width: 32px;
    height: 32px;
    object-fit: cover;
}

.subHeader>div h3 {
    font-size: 31px;
    font-weight: 500;
    line-height: 41px;
    font-style: normal;
    color: var(--grey);
    font-family: Archivo;
}

.subContainer {
    gap: 24px;
    display: flex;
    max-width: 490px;
    min-width: 390px;
    padding: 24px 32px;
    border-radius: 10px;
    flex-direction: column;
    background: var(--white);
    justify-content: space-around;
}

.subContainer>div {
    gap: 12px;
    display: flex;
    flex-direction: column;
}

.subContainer>div>span {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    font-style: normal;
    font-family: Archivo;
}

.subContainer>div>p {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    font-style: normal;
    font-family: Archivo;
}

.subContainer>div>img {
    width: 100%;
    height: 240px;
    border-radius: 5px;
    object-fit: cover;
}

@media screen and (max-width: 1268px) {
    .subContainer {
        padding: 16px;
    }
}

@media screen and (max-width: 1024px) {
    .subHeader {
        padding: 0;
        margin: 2rem 0 1rem;
    }

    .subHeader>div h3 {
        font-size: 18px;
        line-height: 27px;
    }

    .subHeader>div>div {
        width: 44px;
        height: 44px;
        padding: 10px;
    }

    .subContainer {
        padding: 0;
    }

    .subContainer>div>p {
        font-size: 14px;
        line-height: 23px;
    }
}