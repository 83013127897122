.container {
  flex: 1;
  gap: 2rem;
  display: flex;
  flex-direction: column;
}

.header {
  gap: 1.2rem;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  justify-content: space-between;
  border-bottom: 1.5px solid var(--grey);
}

.header > h4 {
  flex: 1;

  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  font-style: normal;
  color: var(--grey);
  font-family: Archivo;
}

.header > button {
  padding: 10px 30px;
  width: fit-content;
}

.body > img {
  width: 100%;
  object-fit: cover;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.buttonContainer > button {
  min-width: 198px;
}

.body {
  border-radius: 20px;
  border: 1px solid #eeebfe;
  background: var(--white);
  padding: 28px;
}
