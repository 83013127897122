.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;
  padding: 0 40px;
}

.container>form {
  flex: 50%;
  border-radius: 10px;
  border: 1px solid var(--veryLightPurple);
  background: var(--white);
  padding: 40px 32px;
}

.container>form>h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 39px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  margin-bottom: 1rem;
}

.container>form>p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 1.5rem;
}

.container>form>div:nth-child(3) {
  margin-bottom: 40px;
}

.container>img {
  height: 18rem;
  max-width: 100%;
  margin-top: 80px;
  position: sticky;
  top: 30px;
}

.buttonSection {
  margin-top: 40px;
}

.buttonSection>button {
  width: 184px;
  margin-left: auto;
}

.tip {
  font-family: Archivo;
  color: var(--grey3) !important;
  font-size: small !important;
  margin-top: 0.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container>form {
    flex: 100%;
    padding: 40px 16px;
  }

  .container>form>h4 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 0.5rem;
  }

  .container>form>p {
    font-size: 12px;
    line-height: 23px;
    margin-bottom: 0.75rem;
  }

  .container>form>div:nth-child(3) {
    margin-bottom: 32px;
  }

  .container>img {
    display: none;
  }

  .buttonSection {
    margin-top: 32px;
  }

  .buttonSection>button {
    width: 156px;
  }
}

@media screen and (max-width: 767px) {
  .container>form {
    flex: 100%;
    padding: 40px 16px;
  }

  .container>form>h4 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 0.5rem;
  }

  .container>form>p {
    font-size: 12px;
    line-height: 23px;
    margin-bottom: 0.75rem;
  }

  .container>form>div:nth-child(3) {
    margin-bottom: 32px;
  }

  .container>img {
    display: none;
  }

  .buttonSection {
    margin-top: 32px;
  }

  .buttonSection>button {
    width: 156px;
  }
}