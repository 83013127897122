.container svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
  object-fit: cover;
}

.container button {
  width: fit-content;
  padding: 10px 30px;
}

.header,
.header > div {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  padding: 24px 48px;
  background: var(--white);
  border-top: 1px solid var(--veryLightPurple);
  height: 94px;
}

.header > div span {
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  font-style: normal;
  color: var(--grey);
  font-family: Archivo;
  cursor: pointer;
}

.header > div button {
  border-color: var(--black);
}

.body {
  gap: 2rem;
  display: flex;
  padding: 40px 48px;
  align-items: flex-start;
  height: calc(100vh - 100px - 100px);
}

.addModules {
  gap: 1.5rem;
  width: 100%;
  display: flex;
  padding: 40px 24px;
  border-radius: 10px;
  flex-direction: column;
  background: var(--white);
}

.addEngagingContent {
  align-items: center;
}

.addModules h3 {
  font-size: 31px;
  font-weight: 500;
  line-height: 41px;
  font-style: normal;
  color: var(--grey);
  font-family: Archivo;
}

.addModulesBottom {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addModulesBottom > div {
  flex: 1;
}

.addModules p {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  font-style: normal;
  color: var(--grey);
  margin-top: -1.5rem;
  font-family: Archivo;
}

.inputSet {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.inputSet > div {
  flex: 1;
  margin-bottom: 0;
}

@media screen and (min-width: 778px) and (max-width: 1024px) {
  .container button {
    padding: 10px;
  }

  .body {
    gap: 1rem;
    padding: 2rem 1rem;
  }

  .addModules p,
  .addModules h3 {
    text-align: center;
  }

  .divider {
    display: none;
    border: none;
  }
}

@media screen and (max-width: 778px) {
  .container svg {
    width: 16px;
    height: 16px;
  }

  .container button {
    padding: 10px;
  }

  .body {
    gap: 2rem;
    padding: 2rem 1rem;
    flex-direction: column;
    background: var(--white);
  }

  .header,
  .header > div {
    gap: 10px;
  }

  .header {
    padding: 16px;
    border-top: none;
    border-bottom: 1px solid var(--veryLightPurple);
  }

  .header > div span {
    font-size: 10px;
    line-height: 18px;
  }

  .header > div button {
    border: none;
    flex-direction: row-reverse;
  }

  .addModules {
    gap: 1rem;
    padding: 1rem;
  }

  .addModules h3 {
    font-size: 20px;
    line-height: 30px;
  }

  .addModules p {
    font-size: 14px;
    line-height: 23px;
    margin-top: -0.8rem;
  }
}
