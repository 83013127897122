.container {
  padding: 40px 2rem;
}

.emptyTab {
  gap: .5rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 6rem 16px;
  border-radius: 10px;
  background: var(--white);
}

.emptyTab img {
  height: 334px;
  object-fit: cover;
}

.emptyTab h4 {
  font-size: 25px;
  font-weight: 500;
  line-height: 35px;
  margin-top: 1.5rem;
}

.emptyTab p {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: var(--grey3);
  max-width: 500px;
}

@media screen and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .emptyTab img {
    height: 200px;
    object-fit: cover;
  }

  .emptyTab h4 {
    font-size: 20px;
    line-height: 30px;
    margin-top: 1rem;
  }

  .emptyTab p {
    font-size: 14px;
    line-height: 22px;
  }
}