.CourseTypeItem {
    gap: 10px;
    width: 47%;
    padding: 10px;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    align-items: flex-start;
}

.CourseTypeItem:hover {
    background: var(--veryVeryLightPurple);
}

.CourseTypeItem div:nth-child(1) {
    display: flex;
    padding: 12px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background: var(--veryLightPurple);
}

.CourseTypeItem p {
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    font-style: normal;
    color: var(--grey);
    font-family: Archivo;
}

.CourseTypeItem span {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    font-style: normal;
    color: var(--grey3);
    font-family: Archivo;
}

@media screen and (max-width: 960px) {
    .CourseTypeItem {
        gap: 20px;
        width: 130px;
        height: 142px;
        padding: 1rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border: 1px solid var(--veryLightPurple);
    }

    .CourseTypeItem p {
        text-align: center;
    }

    .CourseTypeItem span {
        display: none;
    }
}