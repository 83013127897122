.container {
  margin: 24px 0;
  padding: 25px 1rem;
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;
}

.header > div:nth-child(1) > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 0.5rem;
}

.header > div:nth-child(1) > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.header > div:nth-child(2) > svg {
  cursor: pointer;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }

  .header > div:nth-child(1) > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .header > div:nth-child(1) > p {
    font-size: 12px;
    line-height: 23px;
  }
}